
.bp5-card { 
  transition: all 0.1s ease-in-out;
  box-shadow: none !important;
  border-radius: var(--border-radius);
}

.bp5-card:active {
  transform: scale(1);
  box-shadow: none !important;
} 

.bp5-card .bp5-control {
  margin-bottom: 0;
}

.bp5-card.on {
  cursor: pointer;
  transform: scale(1);
  background-color: var(--active-card-background);
}

.bp5-card.on:hover {
  transform: scale(1.02);
}

.bp5-card.on:active {
  transform: scale(1);
}

.bp5-card.off {
  cursor: pointer;
  transform: scale(1);
  background-color: var(--inactive-card-background);
}

.bp5-card.off:hover {
  transform: scale(1.01);
}

.bp5-card.off:active {
  transform: scale(1);
}

.bp5-label {
  color: var(--text-color);
}

.bp5-input { 
  color: var(--input-text-color);
  background-color: var(--input-background-color) !important;
  box-shadow: none !important;
  color: var(--input-text-color) !important;
  border-radius: var(--border-radius) ;
}

.bordered input {
  border: 1px solid var(--border-color);
}

.bp5-intent-danger .bordered input {
  border: 1px solid var(--danger-color);
}

.bp5-form-group.bp5-intent-danger .bp5-form-helper-text {
  color: var(--danger-color);
}

.bp5-input::placeholder {
  color: var(--input-placeholder-color);
}

.bp5-input:focus { 
  background-color: var(--focused-input-background-color) !important;
  box-shadow: none !important;
}

.bp5-input-group .far {
  position: absolute;
  top: 31%;
  left: 12px;
  font-size: 16px;
  color: var(--input-placeholder-color)
}

.bp5-button {
  font-weight: 500;
  border-radius: var(--border-radius);
  border: none !important;
  box-shadow: none !important;
}
.bp5-button.bp5-intent-primary {
  color: var(--primary-button-color);
  background-color: var(--primary-button-background);
}

.bp5-button.bp5-intent-primary:hover {
  background-color: var(--primary-button-background-hover) ;
}

.bp5-button.bp5-intent-primary:active {
  background-color: var(--primary-button-background-active);
}

.bp5-button.bp5-intent-warning {
  background-color: #d9822b
}


.bp5-button.bp5-intent-primary:disabled {
  color: var(--primary-button-color);
  background-color: var(--primary-button-background);
  opacity: 0.7;
}

.bp5-callout {
  font-size: 16px;
  border-radius: var(--border-radius);
}


.bp5-callout .far {
  position: absolute;
  left: 13px;
  top: 13px;
}

.bp5-callout.bp5-intent-danger {
  color: var(--danger-color) !important;
}

.bp5-callout.bp5-intent-danger .far {
  color: var(--danger-color) !important;
}

.bp5-tag {
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
}

.bp5-tag.bp5-minimal.bp5-intent-success {
  color: #4caf50;
}

.bp5-tag.bp5-minimal.bp5-intent-danger {
  color: #e91e63;
}

.bp5-tag.grey {
  background-color: var(--appbar-background-color);
  color: var(--light-text-color);
  border: 1px solid var(--light-text-color);
}

.bp5-tag.basic,
.bp5-tag.advanced,
.bp5-tag.premium {
  background-color: var(--appbar-background-color);
  color: #00BCD4;
  border: 1px solid #00BCD4;
  transform: scale(1.1);
}

.bp5-control.bp5-switch:hover input:checked ~ .bp5-control-indicator,
.bp5-control.bp5-switch input:checked ~ .bp5-control-indicator {
  background: rgba(3, 169, 244, 0.3);
}

 .bp5-control.bp5-switch:hover input ~ .bp5-control-indicator {
  background: rgba(0,0,0,0.1);
}

 .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator::before,
 .bp5-control.bp5-switch .bp5-control-indicator::before {
  box-shadow: none;
  background: rgba(255,255,255,0.2);
}

 .bp5-control.bp5-switch input:checked ~ .bp5-control-indicator::before {
  box-shadow: none;
  background: #03A9F4;
}

.bp5-popover .bp5-popover-content {
  /* background: #403f44; */
  background: var(--modal-background-color)
}

.company-selector .bp5-popover-content {
  width: 300px ;
}

.bp5-menu {
  background: transparent !important;
  color: var(--text-color);
}

.bp5-menu-item.bp5-intent-primary {
  color: var(--primary-button-background);
}


.bp5-menu-item.bp5-intent-primary:hover {
  background: var(--menu-item-background-color);
  color: var(--primary-button-background);
}

.bp5-menu-item.bp5-active {
  background: var(--menu-item-background-color);
    color: var(--menu-item-text-color) !important;
}


/* .bp5-menu-item:hover, */
.bp5-menu-item.bp5-active.bp5-intent-primary {
  background: var(--menu-item-background-color);
  color: var(--menu-item-text-color) !important;
}

.bp5-input-group > .bp5-icon {
  color: var(--light-text-color);
}

.round.bp5-large {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.bp5-tooltip .bp5-popover-content {
  background: var(--tooltip-background-color);
  color: var(--tooltip-text-color);
}

.bp5-tooltip .bp5-popover-arrow-border, .bp5-tooltip .bp5-popover-arrow-fill {
  fill: var(--tooltip-background-color) 
}

.bp5-dialog {
  background: var(--modal-background-color);
}

.bp5-dialog-header {
  background: var(--modal-background-color);
  box-shadow: none;
}

.bp5-dialog-footer-actions {
  position: sticky;
  bottom: 0;
  padding: 10px;
  background: var(--modal-background-color);
}

.bp5-text-muted {
  color: var(--light-text-color);
}

.bp5-menu-header > h6 {
  font-weight: normal;
  color: var(--light-text-color);
}

.bp5-popover .bp5-heading {
    font-weight: normal;
    color: var(--light-text-color);
}
