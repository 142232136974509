body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root::after {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: var(--page-background-image);
}

:focus {
  outline: none !important;
}

.dark-mode {
  --text-color: #fff;
  --light-text-color: #ccc;
  --page-background-image: radial-gradient(#403f44,#262731);
  --appbar-background-color: #262731;
  --active-card-background: rgba(255,255,255,0.1);
  --inactive-card-background: rgba(255,255,255,0.05);
  --border-color: rgba(255,255,255,0.2);
  --input-text-color: var(--text-color);
  --input-background-color: rgba(255,255,255,0.1);
  --input-placeholder-color: #999;
  --focused-input-background-color: rgba(255,255,255,0.15);
  --border-radius: 6px;
  --primary-button-color: #fff;
  --primary-button-background: #03A9F4;
  --primary-button-background-hover: #039be5;
  --primary-button-background-active: #0288d1;
  --danger-color: #ff5263;
  --tooltip-background-color: #ddd;
  --tooltip-text-color: #333;
  --modal-background-color: rgb(79, 79, 83);
  --modal-background-color: #3c3e45;
  --border-color: rgba(255,255,255,0.2);
  --menu-item-background-color: #ddd;
  --menu-item-text-color: #333;
  --tag-background: rgb(74, 74, 80);
}

.light-mode {
  --text-color: #333;
  --light-text-color: #777;
  --page-background-image: radial-gradient(circle at center, #fafafa ,#eee );
  --active-card-background: rgba(255,255,255,0.8);
  --inactive-card-background: rgba(0,0,0,0.05);
  --border-color: rgba(0,0,0,0.1);
  --input-text-color: var(--text-color);
  --input-background-color: rgba(0,0,0,0.01);
  --input-placeholder-color: #999;
  --focused-input-background-color: rgba(255,255,255,0.15);
  --border-radius: 6px;
  --primary-button-color: #fff;
  --primary-button-background: #03A9F4;
  --primary-button-background-hover: #039be5;
  --primary-button-background-active: #0288d1;

  --appbar-background-color: white;
  --danger-color: #ff5263;
  --tooltip-background-color: #333;
  --tooltip-text-color: white;
  --modal-background-color: white;
  --modal-background-color: white;
  --menu-item-text-color: var(--text-color);
  --tag-background: white;
}

@-webkit-keyframes autofill {
  to {
      color: var(--input-text-color);
      background: var(--input-background-color);
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
